&--signup
{
	background-color: $auth-off-white;
	.section__heading
	{
		text-align: center;
	}
	form
	{
		margin: 0 auto 0;
		max-width: 1024px;
		.submit-container
		{
			text-align: center;
			margin-top: 50px;
			.button
			{
				margin-bottom: 0;
			}
		}
	}
}