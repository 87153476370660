&--how
{
	text-align: center;
	background-color: #f6f8fa;
	.steps // ol
	{
		margin: 50px 0;
		list-style-type: none;
		li
		{
			background-color: $white;
			box-shadow: 0 0 29px rgba(black, 0.01);
			width: 300px;
			max-width: 100%;
			padding: 35px 15px;
			margin: 15px auto;
			+ li
			{
				position: relative;
				&::before
				{
					content: '';
					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%);
					border-top: 24px solid #F5F7F9;
					border-left: 34px solid transparent;
					border-right: 34px solid transparent;
				}
			}
			p
			{
				margin-bottom: 0;
			}
			img
			{
				margin-bottom: 24px;
				max-width: 100%;
				width: 143px;
			}
			.counter
			{
				font-weight: 900;
				color: #e3795b;
			}
		}
		@include breakpoint(medium)
		{
		}
	}
	.sell
	{
		margin: 0;
		background-color: $white;
		box-shadow: 0 0 29px rgba(black, 0.01);
		padding: 8vw;
		.images
		{
			max-width: 640px;
			margin: 0 auto;
			text-align: center;
		}
		.kindle,
		.ibooks,
		.tablet
		{
			margin: 10px;
		}
		.kindle
		{
			width: 222px;
			margin-right: 50px;
			margin-top: 17px;
		}
		.ibooks
		{
			width: 162px;
		}
		.tablet
		{
			width: 395px;
			margin-top: 40px;
		}
		h2
		{
			max-width: 17em;
			margin: 50px auto 20px;
		}
		.button
		{
			margin-bottom: 0;
		}
	}
	@include breakpoint(medium)
	{
		.steps
		{
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: stretch;
			margin: 100px 0;
			li
			{
				flex: 1 0 0;
				margin-top: 0;
				+ li
				{
					margin-left: 20px;
					&::before
					{
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						border-left: 24px solid #F5F7F9;
						border-top: 34px solid transparent;
						border-bottom: 34px solid transparent;
						border-right: none;
					}
				}
			}
		}
		.sell
		{
			padding: 110px;
		}
	}
}