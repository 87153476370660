&--hero
{
	background-color: #f6f8fa;
	background-image: linear-gradient(64deg, #dc6259 20%, #a54866 80%);
	color: $white;
	position: relative;
	overflow-y: visible;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	text-align: center;
	.books
	{
		max-width: 320px;
		margin-top: 50px;
	}
	.section__content
	{
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;
		z-index: 1;
	}
	h1
	{
		max-width: 7em;
		margin: 0 auto;
	}
	.button
	{
		background-color: $white;
		color: $auth-blue;
		margin-top: 25px;
	}
    
     &.section--resource{margin-bottom:200px;min-height: 300px;
            .section__content{padding: 50px 0; position: relative;padding-bottom:250px;
                &.text-center{-ms-align-items: center;align-items: center;}
            }
            .video__mainframe{display: block;width: 100%;height: auto;-webkit-transform: translateY(250px);-ms-transform: translateY(250px);-o-transform: translateY(250px);transform: translateY(250px);position: relative;z-index: 1;position: absolute; left: 0;bottom:0; 
                iframe{height: 320px;width: 100%;position: relative;display: block;background: #000}
                .video-togle-btn{max-width: 50px;display: inline-block;
                    img{max-width: 100%;}
                }
                
            }
        }
	@include breakpoint(medium)
	{
		min-height: 750px;
		margin-bottom: 20vw;
		text-align: left;
		.section__content
		{
			align-items: flex-start;
		}
		.books
		{
			position: absolute;
			left: 35%;
			bottom: -18vw;
			z-index: 0;
			max-height: 818px;
			max-width: 85%;
		}
		h1
		{
			max-width: none;
			margin: 0 0 20px;
		}
		p
		{
			max-width: 30em;
		}
		.button
		{
			margin-top: 40px;
		}
          &.section--resource{margin-bottom:220px;min-height: 660px;
            .section__content{padding: 0; position: relative;padding-bottom:210px;
                &.text-center{-ms-align-items: center;align-items: center;}
            }
            .video__mainframe{display: block;width: 100%;height: auto;-webkit-transform: translateY(325px);-ms-transform: translateY(325px);-o-transform: translateY(325px);transform: translateY(325px);position: relative;z-index: 1;position: absolute; left: 0;bottom:0; 
                iframe{height: 440px;width: 100%;position: relative;display: block;background: #000}
            }
        }
	}
	@include breakpoint(xlarge)
	{
		margin-bottom: 200px;
        &.section--resource{margin-bottom:250px;
            .section__content{padding:150px 0;position: relative;padding-bottom:420px;
                &.text-center{-ms-align-items: center;align-items: center;}
            }
            .video__mainframe{display: block;width: 100%;height: auto;-webkit-transform: translateY(325px);-ms-transform: translateY(325px);-o-transform: translateY(325px);transform: translateY(325px);position: relative;z-index: 1;position: absolute; left: 0;bottom:0; 
                iframe{height: 650px;width: 100%;position: relative;display: block;}
            }
        }
	}
	@include breakpoint(xlarge)
	{
		.books
		{
			max-width: 966px;
			bottom: -207px;
		}
	}
    
  
}
