&--about
{
	background-image:
		url('../images/about-bg.jpg'),
		linear-gradient(to right, #5ca3bb, #69bba6);
	background-size: cover;
	color: $white;
	text-align: center;
	.section__content
	{
		max-width: 38em;
		margin: 0 auto;
	}
}