.button
{
	font-weight: 600;
	&.alert
	{
		color: $white;
		&:hover,
		&:focus
		{
			color: $white;
		}
	}
	&.white
	{
		background-color: $white;
		color: $black;
		&:hover
		{
			color: $black;
		}
	}
    &.border-alert
	{
		background-color: $white;
		color: get-color(alert); 
        border:1px solid get-color(alert);  
		&:hover
		{
			color: $black;
		}
	}
}