form
{
	[type=color],
	[type=date],
	[type=datetime-local],
	[type=datetime],
	[type=email],
	[type=month],
	[type=number],
	[type=password],
	[type=search],
	[type=tel],
	[type=text],
	[type=time],
	[type=url],
	[type=week],
	textarea
	{
		border-width: 0 0 1px 0;
	}
	label
	{
		color: rgb(60, 73, 90);
		text-transform: uppercase;
		.required
		{
			color: rgb(195, 48, 50);
		}		
	}
	.g-recaptcha
	{
		// float:  right;
		margin-top: 25px;
	}
	.form-message
	{
		margin: 20px 0;
		text-align: center;
		min-height: 20px;
	}
	.form-error
	{
		display: block;
	}
	p.error
	{
		font-size: 18px;
		font-weight: 600;
		margin: 0;
		color: rgb(195, 48, 50);
		text-transform: none;
	}	
}
.form-success
{
	text-align: center;
}