.section
{
	padding: 0 20px;
	&__content
	{
		width: 100%;
		max-width: 1154px;
		padding: 50px 0;
		margin: 0 auto;
	}
	@include breakpoint(medium)
	{
		&__content
		{
			padding: 150px 0;
		}
	}
	@import 'hero';
	@import 'how';
	@import 'about';
	@import 'benefits';
	@import 'cta';
	@import 'signup';
	@import 'videothumb';
}