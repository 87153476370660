&--cta
{
	background-color: #dc675b;
	background-image: linear-gradient(to right, #d4555b, #e3795b); 
	color: $white;
	position: relative;
	text-align: center;
	.section__content
	{
		padding-top: 100px;
	}
	.two-books
	{
		position: absolute;
		top: -100px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 200px;
	}
	@include ie10-11
	{
		.two-books
		{
			width: 200px;
		}
	}
}