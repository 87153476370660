&--videothumb{
    .shorting--search{width: 100%;margin-bottom: 30px;
            .filter--tabs{-webkit-flex: 1;-moz-flex: 1;-ms-flex: 1;-o-flex: 1;flex: 1;display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;-ms-align-items: center;align-items: center;width: 100%; margin:0 auto; margin-bottom: 20px;flex-wrap: wrap;
                li{margin: 0;display: inline-block;vertical-align: middle;padding: 0 3px; 
                    a{color: $tab-video-color;font-weight: 300;font-size: 16px;position: relative;
                        &:before{background: #db6059;height: 2px;width: 0; left: 0;transition: all .3s ease;position: absolute;content: '';bottom: -4px;}
                    }
                    &:hover,&.active{
                        a{font-weight: 500;
                            &:before{width: 100%;}
                        }
                    }
                }
            }
            .video--search{max-width: 254px;margin: 0;background: #fafbfc;-webkit-border-radius: 5px; border-radius: 5px;-webkit-box-shadow:0px 0px 10px rgba(0,0,0,0.8);  box-shadow:0px 0px 10px rgba(0,0,0,0.8);border: none;background-image: url(../images/icon-search.png);background-repeat: no-repeat;background-position: 95% center; background-position:calc(100% - 10px) center;padding-right: 38px;height: 1.77634rem;}
        }
    @include breakpoint(medium){
        .shorting--search{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;align-items: center;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;width: 100%;margin-bottom: 30px;
            .filter--tabs{-webkit-flex: 1;-moz-flex: 1;-ms-flex: 1;-o-flex: 1;flex: 1;display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;-ms-align-items: center;align-items: center;width: 100%; margin:0 auto; margin-right: 30px;
                li{margin: 0;display: inline-block;vertical-align: middle;padding: 0 3px; 
                    a{color: $tab-video-color;font-weight: 300;font-size: 18px;position: relative;
                        &:before{background: #db6059;height: 3px;width: 0; left: 0;transition: all .3s ease;position: absolute;content: '';bottom: -4px}
                    }
                    &:hover,&.active{
                        a{font-weight: 500;
                            &:before{width: 100%;}
                        }
                    }
                }
            }
            .video--search{max-width:180px;margin: 0;background: #fafbfc;-webkit-border-radius: 5px; border-radius: 5px;-webkit-box-shadow:0px 0px 10px rgba(0,0,0,0.8);  box-shadow:0px 0px 10px rgba(0,0,0,0.8);border: none;background-image: url(../images/icon-search.png);background-repeat: no-repeat;background-position: 95% center; background-position:calc(100% - 10px) center;padding-right: 38px;height: 1.77634rem;font-size: 16px;}
        }
    }
    @include breakpoint(large){
        .shorting--search{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;align-items: center;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;width: 100%;margin-bottom: 30px;
            .filter--tabs{-webkit-flex: 1;-moz-flex: 1;-ms-flex: 1;-o-flex: 1;flex: 1;display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;-ms-align-items: center;align-items: center;width: 100%; margin:0 auto; margin-right: 30px;
                li{margin: 0;display: inline-block;vertical-align: middle;padding: 0 3px; 
                    a{color: $tab-video-color;font-weight: 300;font-size: 22px;position: relative;
                        &:before{background: #db6059;height: 3px;width: 0; left: 0;transition: all .3s ease;position: absolute;content: '';bottom: -4px}
                    }
                    
                }
            }
            .video--search{max-width:220px;}
            
        }
    }
    @include breakpoint(xlarge){
        .shorting--search{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;align-items: center;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;width: 100%;margin-bottom: 30px;
            .filter--tabs{-webkit-flex: 1;-moz-flex: 1;-ms-flex: 1;-o-flex: 1;flex: 1;display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;-ms-align-items: center;align-items: center;width: 100%; margin:0 auto; margin-right: 30px;
                li{margin: 0;display: inline-block;vertical-align: middle;padding: 0 3px; 
                    a{color: $tab-video-color;font-weight: 300;font-size: $tab-video-fontsize;position: relative;
                        &:before{background: #db6059;height: 3px;width: 0; left: 0;transition: all .3s ease;position: absolute;content: '';bottom: -4px}
                    }
                    &:hover,&.active{
                        a{font-weight: 500;
                            &:before{width: 100%;}
                        }
                    }
                }
            }
            .video--search{max-width: 254px;margin: 0;background: #fafbfc;-webkit-border-radius: 5px; border-radius: 5px;-webkit-box-shadow:0px 0px 10px rgba(0,0,0,0.8);  box-shadow:0px 0px 10px rgba(0,0,0,0.8);border: none;background-image: url(../images/icon-search.png);background-repeat: no-repeat;background-position: 95% center; background-position:calc(100% - 10px) center;padding-right: 38px;height: 1.77634rem;}
        }
    }
    .video__thumbs{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;-webkit-justify-content: flex-start;-moz-justify-content: flex-start;-ms-justify-content: flex-start;-o-justify-content: flex-start;justify-content: flex-start;align-items: flex-start;width: 100%;-webkit-flex-wrap: wrap;-moz-flex-wrap: wrap;-ms-flex-wrap: wrap;-o-flex-wrap: wrap;flex-wrap: wrap;margin: 0;padding: 30px 0 0;
        li{display: inline-block;vertical-align: top;width:calc(33.33% - 35px);padding: 0;margin: 0 25px 50px;max-width: 350px;-webkit-box-shadow:0px 10px 11px rgba(0,0,0,0.03);  box-shadow:0px 10px 11px rgba(0,0,0,0.03);
            &:nth-child(3n + 1){margin-left: 0;}
            &:nth-child(3n - 0){margin-right: 0;}
            a{background: #fff;display: inline-block; padding: 25px;}
            iframe{width: 100%;height: 175px;}
            .vid-caption-area{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;width: 100%;align-items: center;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;
                h2{font-size: 1em;font-weight: 300;padding-right: 10px;}
                .play-icon{background-image: url(../images/play-icon.png);width: 33px; height: 33px;background-size: 100% auto;background-repeat: no-repeat;background-position: center center;}
            }
        }
    }
        

}
.pagination{margin: 0 auto;text-align: center;padding-top: 55px;
    li{display: inline-block;vertical-align: middle;padding: 0;
         a{display: block;margin: 0 3px;padding: 5px;line-height: 38px;min-width: 50px;text-align: center;font-size: 24px;font-weight:700;color: #00afb3;
            &.active,&:hover{color: #db6059;}
        }
        .pg-next,.pg-prev{display: inline-block;vertical-align: middle;text-transform: uppercase;font-weight: 700;border: none;margin: 0 20px;
            &:hover,&:hover{color: #f3a380;background: rgba(0,0,0,0);}
        } 
    }
   .pg-desbl{color: #ccc;pointer-events: none;font-weight: 400;}
}
@media (min-width:280px) and (max-width:767px) {
    .pagination{padding-top:25px;
        li{
             a{line-height:28px;min-width:25px;text-align: center;font-size: 16px;margin: 0 2px; padding: 0}
            .pg-next,.pg-prev{margin: 0 5px;max-width: 25px}
        }
    }
    &--videothumb{
        .video__thumbs{justify-content: center;
            li{width: 100%;max-width: 270px;margin: 0 10px 20px;
                a{padding: 10px}
                &:nth-child(3n + 1),&:nth-child(3n - 0){margin: 0 10px 20px;}
                .vid-caption-area {
                    h2{font-size: 17px;}                    
                }
            }
        }    
    }   
}


@media (min-width:768px) and (max-width:1023px) {
    &--videothumb{
        .video__thumbs{justify-content: center;
            li{width: 100%;max-width: 270px;margin: 0 10px 20px;
                a{padding: 10px}
                &:nth-child(3n + 1),&:nth-child(3n - 0){margin: 0 10px 20px;}
                .vid-caption-area {
                    h2{font-size: 17px;}                    
                }
            }
        }    
    }   
}
@media (min-width:1024px) and (max-width:1200px) {
    &--videothumb{
         .video__thumbs{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;-webkit-justify-content: flex-start;-moz-justify-content: flex-start;-ms-justify-content: flex-start;-o-justify-content: flex-start;justify-content: flex-start;align-items: flex-start;width: 100%;-webkit-flex-wrap: wrap;-moz-flex-wrap: wrap;-ms-flex-wrap: wrap;-o-flex-wrap: wrap;flex-wrap: wrap;margin: 0;padding: 30px 0 0;
        li{display: inline-block;vertical-align: top;width:calc(33.33% - 20px);padding: 0;margin: 0 15px 30px;max-width: 350px;-webkit-box-shadow:0px 10px 11px rgba(0,0,0,0.03);  box-shadow:0px 10px 11px rgba(0,0,0,0.03);
            &:nth-child(3n + 1){margin-left: 0;}
            &:nth-child(3n - 0){margin-right: 0;}
            a{background: #fff;display: inline-block; padding:15px;}
            iframe{width: 100%;height: 175px;}
            .vid-caption-area{display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;width: 100%;align-items: center;-webkit-justify-content: space-between;-moz-justify-content: space-between;-ms-justify-content: space-between;-o-justify-content: space-between;justify-content: space-between;
                h2{font-size: 18;font-weight: 300;padding-right: 10px;}
                .play-icon{background-image: url(../images/play-icon.png);width: 33px; height: 33px;background-size: 100% auto;background-repeat: no-repeat;background-position: center center;}
            }
        }
    }
    }   
}