.mfp-content
{
	max-width: 800px;
	.section--signup
	{
		border-radius: 5px;
		.section__content
		{
			padding: 50px 0;
		}
	}
}

/* Animation: overlay at start */
.mfp-fade
{
	&.mfp-bg
	{
		opacity: 0;
		transition: all 0.15s ease-out;
		/* overlay animate in */
		&.mfp-ready
		{
			opacity: 0.8;
		}
		/* overlay animate out */
		&.mfp-removing {
			opacity: 0;
		}
	}
	/* content at start */
	&.mfp-wrap
	{
		.mfp-content
		{
			opacity: 0;
			transition: all 0.15s ease-out;
		}
		/* content animate it */
		&.mfp-ready .mfp-content {
			opacity: 1;
		}
		/* content animate out */
		&.mfp-removing .mfp-content {
			opacity: 0;
		}
	}
}