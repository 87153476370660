&--benefits
{
	text-align: center;
	.section__content
	{
		margin-bottom: 100px;
	}
	ul
	{
		list-style: none;
		padding-left: 1em;
		text-align: left;
		max-width: 28em;
		margin: auto;
		margin-left: 35px;
		line-height: 2.1;
		li
		{
			text-indent: -35px;
			&::before
			{
				content: ' ';
				display: inline-block;
				height: 1em;
				width: 1em;
				vertical-align: -0.1em;
				background-image: url('../images/round-done-button.png');
				background-image: url('../svg/checkmark.svg');
				background-size: contain;
				background-repeat: no-repeat;
				margin-right: 0.5em;
			}
		}
	}
	@include breakpoint(medium)
	{
		.section__content
		{
			margin-bottom: 0;
		}
		ul
		{
			margin-left: auto;
		}
	}
}