.site
{
	&__header
	{
		padding: 0 20px;
		background-color: $white;
		.section__content
		{
			padding: 20px 0;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
		}
		.logo
		{
			width: 135px;
			max-width: 100%;
		}
		.coming-soon
		{
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			margin: 10px 0;
			p
			{
				display: none;
				margin-bottom: 0;
			}
			.button
			{
				margin: 0 0 0 20px;  
                 &.border-alert{margin-top: 5px;margin-bottom: 5px;}
			}
		}
		@include breakpoint(medium)
		{
            
			.section__content
			{
				margin: 0 auto;
				justify-content: space-between;
				padding: 50px 0;
			}
			.logo
			{
				width: 215px;
			}
			.coming-soon
			{
				justify-content: flex-end;
				p
				{
					display: block;
				}
				.button
				{
					margin: 0 0 0 40px;  
                } 
			}
		}       
	}
    

    

}

.loader{display: block;margin:0 auto;opacity: 0;visibility: hidden; overflow: hidden; text-align: center; position: fixed;width: 100%; top: 0; left: 0; height:0;
    img{width: 24px; height: 26px;margin: 0;display: inline-block;}
    &.active{opacity: 1;padding: 20px 0 10px; height: 100%; visibility: visible;background: #fff;z-index: 999;display: -webkit-flex;display: -moz-flex;display: -ms-flex;display: -o-flex;display: flex;align-items: center;-webkit-justify-content: center;-moz-justify-content: center;-ms-justify-content: center;-o-justify-content: center;justify-content: center;
     img {-webkit-animation-name: error-img-rotet; -webkit-animation-duration: 1300ms; -webkit-animation-iteration-count: infinite; -webkit-animation-timing-function: linear; -moz-animation-name: error-img-rotet; -moz-animation-duration: 1300ms; -moz-animation-iteration-count: infinite; -moz-animation-timing-function: linear; -ms-animation-name: error-img-rotet; -ms-animation-duration: 13000ms;  -ms-animation-iteration-count: infinite; -ms-animation-timing-function: linear; animation-name: error-img-rotet;   animation-duration: 1300ms;   animation-iteration-count: infinite; animation-timing-function: linear;}
    }
}

@-ms-keyframes error-img-rotet {from { -ms-transform: rotate(360deg); }to { -ms-transform: rotate(0deg); }}
@-moz-keyframes error-img-rotet {from { -moz-transform: rotate(360deg); }to { -moz-transform: rotate(0deg); }}
@-webkit-keyframes error-img-rotet {from { -webkit-transform: rotate(360deg); }to { -webkit-transform: rotate(0deg); }}
@keyframes error-img-rotet { from {transform:rotate(360deg);} to{transform:rotate(0deg);}}