.site
{
	&__footer
	{
		padding: 20px 0;
		text-align: center;
		font-size: 0.75em;
		p
		{
			margin: 0;
		}
	}
}

