.section--resource{display: none;} 

 .video__mainframe{
    .video-maincaption{padding-top: 30px;text-align: left;
        h2{font-size: 18px;text-transform: uppercase;font-weight: 600;margin-bottom: 10px;color: #000;text-align: left}
        p{font-size: 16px;font-weight: 300;color: #000;text-align: left}
    }
}
    @include breakpoint(medium){
    .video__mainframe{background: rgba(0,0,0,0.7);
        .video-maincaption{position: absolute;bottom: 10%;left: 5%;width: 90%;text-align: left;opacity: 1;transition: all .3s ease;
            h2{font-size: 32px;text-transform: uppercase;font-weight: 600;margin-bottom: 10px;text-shadow: 0 0 1px rgba(0,0,0,0.5);color: #fff}
            p{font-size: 22px;font-weight: 300;color: $white;text-shadow: 0 0 1px rgba(0,0,0,0.5);}
        }

    }
}
